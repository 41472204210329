import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getSingleCar, updateCar } from "../services/photolisting/car";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";

function Ratingcarlistinfo() {
  // for image
  // const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState();
  // console.log(imgData);
  // const imagesPreview = (e) => {
  //   if (e.target.files[0]) {
  //     setPicture(e.target.files[0]);
  //     const reader = new FileReader();
  //     reader.readAsDataURL(e.target.files[0]);
  //     reader.addEventListener("load", () => {
  //       setImgData(reader.result);
  //     });
  //   }
  // };

  const [queryParameters] = useSearchParams();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  // getData
  const getFunc = async () => {
    const response = await getSingleCar(queryParameters.get("id"));
    if (response.status === 200) {
      // setLoder(false)
      reset(response.data.data);
      setImgData(response?.data?.data?.ImageUrl);
    } else {
      Swal.fire({
        icon: "error",
        title: response.data.message || "Server Error",
        timer: 2000,
        showConfirmButton: false,
      });
      // setLoder(false)
    }
  };
  useEffect(() => {
    getFunc();
  }, []);

  // submit form
  const navigate = useNavigate();
  const [isactive, setIsActive] = useState(false);

  const onSubmit = async (fData) => {
    debugger;

    setIsActive(true);
    const data = {
      photoListingId: Number(queryParameters.get("id")),
      levelId: activeTab,
      price: Number(fData.price),
    };
    // console.log(data);
    const resp = await updateCar(data);
    if (resp.status === 200) {
      Swal.fire({
        icon: "success",
        title: resp.data.message,
        timer: 2000,
        showConfirmButton: false,
      });
      navigate("/carlist");
      setIsActive(false);
    } else {
      Swal.fire({
        icon: "error",
        title: resp.data.message,
        timer: 2000,
        showConfirmButton: false,
      });
      setIsActive(false);
    }
  };
  return (
    <div className="user-data px-[40px] py-[20px]">
      <div className="flex">
        <h2 className="text-MainHeading text-DashHeading font-fwRegular">
          Change Car Rating
        </h2>
      </div>
      <div className="user-data-info view-employee-details">
        <div className="flex">
          <div className="w-4/12">
            <img
              src={imgData}
              className="ratingIMG"
              style={{ borderRadius: "50%", margin: "auto", marginTop: "20px" }}
            />
          </div>

          <div className="w-8/12">
            <h2 className="text-MainHeading text-DashHeading font-fwRegular">
              Rating
            </h2>

            <div className="tabs">
              <button
                className={`tab-button  ${activeTab === 1 ? "active-tab" : ""}`}
                onClick={() => handleTabClick(1)}
              >
                Level 1
              </button>
              <button
                className={`tab-button ${activeTab === 2 ? "active-tab" : ""}`}
                onClick={() => handleTabClick(2)}
              >
                Level 2
              </button>
              <button
                className={`tab-button ${activeTab === 3 ? "active-tab" : ""}`}
                onClick={() => handleTabClick(3)}
              >
                Level 3
              </button>
              <button
                className={`tab-button ${activeTab === 4 ? "active-tab" : ""}`}
                onClick={() => handleTabClick(4)}
              >
                Level 4
              </button>
            </div>
          </div>
        </div>

        <form style={{ marginTop: "-90px" }}>
          <div className="flex">
            <div className="w-4/12">
              {/* <img src={imgData} width={"215px"} alt="" height={"215px"} style={{ borderRadius: "50%", margin: "auto", marginTop: "20px" }} /> */}
            </div>

            <div className="w-8/12">
              <div className="mt-5">
                <label className="font-medium text-paragraph font-fwRegular ">
                  Enter Custom Price
                </label>
                <br></br>
                <input
                  type="text"
                  className="w-6/12 text-paragraph px-[24px] my-3 py-[15px] border-[1px]  rounded-[24px] border-[#F1F1F1]"
                  placeholder="$0"
                  {...register("price", { required: true })}
                />
                <br></br>
                {errors.price && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </div>
            </div>
          </div>
          <button
            onClick={handleSubmit(onSubmit)}
            className="w-40 bg-btn float-right mt-14 my text-paragraph rounded-[26px] py-[10px] text-[#ffffff]"
            disabled={isactive}
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
}

export default Ratingcarlistinfo;
