import React from 'react'
import logo from '../assets/media/logo.svg';

function PrivacyPolicy() {
  return (
    <>
    <div className='privacyHeader bg-topHeadercolor px-20 py-5'>
    <img src={logo} width={'181px'} alt='Logo' />
    </div>
    <div className='px-20 py-10'>
    <h2 class="text-MainHeading  text-DashHeading font-fwRegular">Photolist Pro Privacy Policy</h2>

    <p className='py-3 '>Welcome to Photolist Pro! At Photolist Pro, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you interact with our website and use our simple photo editing tool.</p>

    <h2 className='text-[25px]'>1. Information We Collect.</h2>
    
    <p className='py-3 '><b>1.1. Personal Information:</b> When you visit our website and use our photo editing tool, we may collect certain personal information, such as your name, email address, and any other information you voluntarily provide while interacting with our platform.
    </p>
    <p className='py-3 '>
    <b>1.2. Device and Usage Information:</b> We may collect information about the devices you use to access our website and the ways in which you interact with our platform. This includes information such as your IP address, browser type, operating system, and browsing activity.</p>
    <h2 className='text-[25px]'>2. How We Use Your Information.</h2>
    <p className='py-3 '>
    <b>2.1. Enhancing User Experience:</b> We use the information collected to personalize and enhance your experience with our photo editing tool. This includes offering personalized features and content tailored to your preferences.
    </p>
    <p className='py-3 '>
    <b>2.2. Communication:</b> We may use your email address to send you important updates, announcements, and other relevant information related to Photolist Pro. You can opt-out of receiving marketing communications at any time.
    </p>
    <p className='py-3 '>
    <b>2.3. Improving our Services:</b>Your feedback and interactions with our platform help us improve our services. We may use aggregated and anonymized data for analytical purposes to identify trends and make informed decisions.
    </p>
    <h2 className='text-[25px]'>3. Information Sharing and Disclosure.</h2>
    <p className='py-3 '>
    <b>3.1. Third-Party Service Providers:</b>We may share your information with trusted third-party service providers who assist us in delivering our services, such as hosting our website, managing communications, or conducting data analysis. These providers are contractually obligated to protect your data and may not use it for any other purpose.
    </p>
    <p className='py-3 '>
    <b>3.2. Legal Requirements:</b>We may disclose your information if required by law, to comply with a legal obligation, or in response to a valid request from law enforcement authorities.
    </p>
    <h2 className='text-[25px]'>4. Data Security.</h2>
    <p className='py-3 '>
    At Photolist Pro, we take data security seriously. We implement industry-standard security measures to protect your information from unauthorized access, disclosure, or destruction.
    </p>
    <h2 className='text-[25px]'>5. Cookies and Tracking Technologies.</h2>
    <p className='py-3 '>
    Our website may use cookies and similar tracking technologies to enhance your browsing experience. These technologies help us understand how you use our platform and improve our services. You can manage your cookie preferences through your browser settings.
    </p>
    <h2 className='text-[25px]'>6. Children's Privacy.</h2>
    <p className='py-3 '>
    Photolist Pro is not intended for use by children under the age of 13. We do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided us with their personal information, please contact us immediately.
    </p>
    <h2 className='text-[25px]'>7. Changes to this Privacy Policy.</h2>
    <p className='py-3 '>
    We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated policy on our website.
    </p>
    <h2 className='text-[25px]'>8. Contact Us</h2>
    <p className='py-3 '>
    If you have any questions or concerns about our Privacy Policy or the way we handle your data, please don't hesitate to contact us at our provided Email.
    </p>
    <p className='py-3 '>
    By using Photolist Pro, you consent to the practices described in this Privacy Policy. Thank you for trusting us with your information, and we are committed to providing you with a safe and enjoyable experience while using our photo editing tool.
    </p>
    <hr></hr>
    </div>
    </>
  )
}

export default PrivacyPolicy