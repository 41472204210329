import React from 'react';
import logo from './assets/media/logo.svg';
import backgroundImage from './assets/media/login-cars-img.svg';
import Loginform from './components/Loginform';
function Login() {
  return (
    <div className="login-main flex w-full">
      <div className="w-5/12 px-[80px] py-[50px]">
        <img src={logo} width={"225px"} alt="Logo" />
        <div className='login-form mt-20'>
            <h2 className='text-[48px] font-semibold'>Login!</h2>
                <Loginform />
        </div>
      </div>
      <div
        className="w-7/12 login-cars h-screen"
        style={{
          backgroundImage: `linear-gradient(136.95deg, rgba(0, 122, 255, 0.31) 6.08%, rgba(255, 255, 255, 0.1) 163.53%), url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      ></div>
    </div>
  );
}

export default Login;
