import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo from '../assets/media/logo.svg';
import Usermanagement from './Usermanagement';
import Carlisting from './Carlisting';
import Levelspricing from './Levelspricing';
import backicon from '../assets/media/backicon.svg';
import Folder from '../Folders/FolderInSidebar';
function Sidebar() {
  const navigate = useNavigate();
  const goBack = () => {
    // window.history.back();
    localStorage.removeItem('photolist');
    navigate('/');
  };

  return (
    <>
      <div className='sidebar'>
        <Link to='/home'>
          <div className='logo-box px-[48px] py-[50px]'>
            <img src={logo} width={'181px'} alt='Logo' />
          </div>
        </Link>
        <div className='sidebar-list'>
          <NavLink to='/home'>
            <Usermanagement />
          </NavLink>
          <NavLink to='/carlist'>
            <Carlisting />
          </NavLink>
          <NavLink to='/folders'>
            <Folder />
          </NavLink>
          <NavLink to='/levelpricing'>
            <Levelspricing />
          </NavLink>
        </div>
        <button onClick={goBack} className=' back-btn bg-listActive px-[15px] py-[10px] rounded-[20px]'><img src={backicon} width={'20px'} /></button>
      </div>
    </>
  );
}

export default Sidebar;
