import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import {
  useAddToFolderMutation,
  useGetFoldersQuery,
} from "../apis/folderApi/folderApi";
import Swal from "sweetalert2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    height: "40%",
    borderRadius: "20px",
  },
};

const AddToFolderModal = ({
  setAddToFolderModal,
  AddToFolderModals,
  selectedFolders,
}) => {
  const [addToFolder] = useAddToFolderMutation();

  const [loading, setLoading] = useState(true);
  const [folders, setFolders] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(selectedFolders.id);

  const { data, isSuccess, isError, refetch } = useGetFoldersQuery([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refetch(); // Fetch the list of folders
        if (isSuccess && data) {
          setFolders(data.data); // Set the fetched folders to the state
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [refetch, isSuccess, data]);
  function closeModal() {
    setAddToFolderModal(false);
  }

  const handleFolderSelection = (event) => {
    setSelectedFolderId(event.target.value);
  };

  function callAddToFolderAPI() {
    const payload = {
      folderId: selectedFolderId,
      photoListingIds: selectedFolders,
    };

    console.log("payload: ", payload);

    const resp = addToFolder(payload);
    Swal.fire({
      title: resp.message,
      timer: 2000,
      icon: "success",
      showConfirmButton: false,
    });

    closeModal();
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Modal
      isOpen={AddToFolderModals}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Add Folder Modal"
    >
      <div className=" pb-2 border-b-2">
        <h2 className=" sm:text-xl text-md font-semiibold">Select Folder</h2>
      </div>
      <form>
        <div className="py-2">
          <div className="flex flex-row justify-between py-1 pb-3 ">
            <label
              htmlFor="newOrderField"
              className="text-smallTtile name-title"
            >
              Folder
            </label>
          </div>
          {isError && <p>Error loading data.</p>}
          {loading && <p>Loading...</p>}
          {isSuccess && !loading && Array.isArray(folders) && (
            <select
              id="folderField"
              name="folderField"
              value={selectedFolderId}
              {...register("folderField")}
              onChange={handleFolderSelection} // Handle the change event
              className="p-2 block w-full rounded-md border-gray-300 shadow-sm bg-white text-black"
            >
              {folders.map((folder) => (
                <option
                  key={folder.id}
                  value={folder.id}
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  {folder.name}
                </option>
              ))}
            </select>
          )}
        </div>

        <div className="flex flex-row justify-end py-1 pt-4">
          <button
            onClick={closeModal}
            className="w-40 text-btn text-paragraph rounded-[26px] py-[10px] border border-btn"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={callAddToFolderAPI}
            className="w-40 ml-3 bg-btn text-paragraph rounded-[26px] py-[10px] text-[#ffffff]"
          >
            Add
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddToFolderModal;
