// import { config } from "../../constants/Constants";
import axios from "../../hoc/axios";

export const addEmploye = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("photolist"))?.data?.token
        }`,
      },
    };
    const response = await axios.post(
      "/admin/employee/addEmployee",
      body,
      config
    );
    return response;
  } catch (err) {
    return err.response;
  }
};
export const editEmploye = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("photolist"))?.data?.token
        }`,
      },
    };
    const response = await axios.post(
      "/admin/employee/editEmployee",
      body,
      config
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getEmployeById = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("photolist"))?.data?.token
        }`,
      },
    };
    const response = await axios.get(
      `/admin/employee/getEmployeeById/${id}`,
      config
    );
    return response;
  } catch (err) {
    return err.response;
  }
};
export const getAllEmployees = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("photolist"))?.data?.token
        }`,
      },
    };
    const response = await axios.get(`/admin/employee/getAllEmployees`, config);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getFolders = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("photolist"))?.data?.token
        }`,
      },
    };
    const response = await axios.get(`/admin/folder/getAllFolders`, config);
    return response;
  } catch (err) {
    return err.response;
  }
};
