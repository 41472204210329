import React from 'react'
import Usertable from './Usertable'
import { Link } from 'react-router-dom';

function Usermanagedata() {
  return (
    <div className='user-data px-[40px] py-[20px]'>
      <div className='flex'>
      <h2 className='text-MainHeading  text-DashHeading font-fwRegular'>User Management</h2>
      <Link to='/home/addemployee' className='ml-auto'>
      <button type='submit' className='w-40 bg-btn  my text-paragraph rounded-[26px] py-[10px] text-[#ffffff]'>Add Employee</button>
      </Link>
      </div>
      <div className='user-data-info'>
          <Usertable/>
      </div>
    </div> 
  )
}

export default Usermanagedata
