import axios from "../../hoc/axios";

export const getSingleCar = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("photolist"))?.data?.token
        }`,
      },
    };
    const response = await axios.get(
      `/admin/listings/getListingById/${id}`,
      config
    );
    return response;
  } catch (err) {
    return err.response;
  }
};
export const getAllCars = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("photolist"))?.data?.token
        }`,
      },
    };
    const response = await axios.get(
      `/admin/listings/getApprovedListings`,
      config
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const deleteCar = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("photolist"))?.data?.token
        }`,
      },
    };
    const response = await axios.post(
      "/admin/listings/deletePhotoListing",
      body,
      config
    );
    return response;
  } catch (err) {
    return err.response;
  }
};
export const updateCar = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("photolist"))?.data?.token
        }`,
      },
    };
    const response = await axios.post(
      "/admin/listings/editPhotoListing",
      body,
      config
    );
    return response;
  } catch (err) {
    return err.response;
  }
};
