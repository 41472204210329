import React from "react";
import levelediticon from "../assets/media/levelediticon.svg";
import { Link } from 'react-router-dom';
function Levelpricingdetails({ data }) {

  return (
    <div className="user-data px-[40px] py-[20px]">
      <div className="flex">
        <h2 className="text-MainHeading  text-DashHeading font-fwRegular">
          Levels Pricing
        </h2>
        <Link to='/levelpricing/editpricing' className='ml-auto'>
          <button type='submit' className='w-40 bg-btn  my text-paragraph rounded-[26px] py-[10px] text-[#ffffff]'>Edit Pricing</button>
        </Link>
      </div>
      <form className="user-data-info pricing">
        <div className="flex flex-wrap w-full">
          {data?.map((item) => {
            return (
              <>
                <div key={item?.id} className="w-4/12 edit-pricing-box">
                  <div className="mt-3 mx-4">
                    {item?.title === "Level 1" ?
                      <label className="font-medium text-paragraph flex font-fwRegular ">
                        Level One Price
                        {/* <img className="ml-auto edit-pricing-icon" src={levelediticon} alt="" /> */}
                      </label> : item?.title === "Level 2" ?
                        <label className="font-medium text-paragraph flex font-fwRegular ">
                          Level Two Price
                          {/* <img className="ml-auto edit-pricing-icon" src={levelediticon} alt="" /> */}
                        </label>
                        : item?.title === "Level 3" ?
                          <label className="font-medium text-paragraph flex font-fwRegular ">
                            Level Three Price
                            {/* <img className="ml-auto edit-pricing-icon" src={levelediticon} alt="" /> */}
                          </label>
                          :
                          <label className="font-medium text-paragraph flex font-fwRegular ">
                            Level Four Price
                            {/* <img className="ml-auto edit-pricing-icon" src={levelediticon} alt="" /> */}
                          </label>
                    }
                    <p
                      type="text"
                      className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px]  rounded-[24px] border-[#F1F1F1]"
                    >{item?.price}</p>
                  </div>
                </div>
                {/* <div className="w-4/12 edit-pricing-box">
                  <div className="mt-3 mx-4">
                    <label className="font-medium text-paragraph flex font-fwRegular ">
                      Level Two Pricing
                      <img className="ml-auto edit-pricing-icon" src={levelediticon} alt="" />
                    </label>
                    <input
                      type="text"
                      className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px]  rounded-[24px] border-[#F1F1F1]"
                      placeholder="$1500"
                    />
                  </div>
                </div> */}
                {/* <div className="w-4/12 edit-pricing-box">
                  <div className="mt-3 mx-4">
                    <label className="font-medium text-paragraph flex font-fwRegular ">
                      Level Three Pricing
                      <img className="ml-auto edit-pricing-icon" src={levelediticon} alt="" />
                    </label>
                    <input
                      type="text"
                      className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px]  rounded-[24px] border-[#F1F1F1]"
                      placeholder="$4000"
                    />
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="w-4/12 edit-pricing-box">
                    <div className="mt-3 mx-4">
                      <label className="font-medium text-paragraph flex font-fwRegular ">
                        Level Four Pricing
                        <img className="ml-auto edit-pricing-icon" src={levelediticon} alt="" />
                      </label>
                      <input
                        type="text"
                        className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px]  rounded-[24px] border-[#F1F1F1]"
                        placeholder="$7000"
                      />

                    </div>
                  </div>
                </div> */}
              </>
            )
          })}
        </div>

        {/* <button
          type="submit"
          className="w-40 bg-btn float-right mt-14 my text-paragraph rounded-[26px] py-[10px] text-[#ffffff]"
        >
          Save
        </button> */}
      </form>


    </div>
  );
}

export default Levelpricingdetails;
