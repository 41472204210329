import React from "react";
// import Student_table from './Student_table'
import Topheader from "./Topheader";
import Usermanagedata from "./Usermanagedata";
function Dashboard() {
  return (
    <>
      <div className="main-table">
        <Topheader />
        <div className="inner-table relative">
          <Usermanagedata />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
