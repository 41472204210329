import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { useCreateFoldersMutation } from "../apis/folderApi/folderApi";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    height: "30%",
    borderRadius: "20px",
  },
};

const AddFolderModal = ({
  setAddFolderModal,
  AddFolderModals,
  folderCreatedListener,
}) => {
  const [createFolder] = useCreateFoldersMutation();
  const [newName, setNewName] = useState("");

  function closeModal() {
    setAddFolderModal(false);
  }
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    createFolder({ name: newName });
    folderCreatedListener();
    closeModal();
    setNewName("");
  };

  return (
    <Modal
      isOpen={AddFolderModals}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Add Folder Modal"
    >
      <div className=" pb-2 border-b-2">
        <h2 className=" sm:text-xl text-md font-semiibold">Add Folder</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-2">
          <label htmlFor="newOrderField" className="text-smallTtile name-title">
            Folder Name
          </label>
          <input
            type="text"
            id="folderNameField"
            name="folderNameField"
            step={1}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className=" p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200"
            placeholder="Enter Folder Name"
          />
        </div>

        <div className="flex flex-row justify-end py-1 pt-4">
          <button
            onClick={closeModal}
            type="submit"
            className="w-40 text-btn text-paragraph rounded-[26px] py-[10px] border border-btn"
          >
            Close
          </button>
          <button
            type="submit"
            className="w-40 ml-3 bg-btn text-paragraph rounded-[26px] py-[10px] text-[#ffffff]"
          >
            Create
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddFolderModal;
