import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import levelediticon from "../assets/media/levelediticon.svg";
// import deleteicon from "../assets/media/deleteicon.svg";
// import viewemployeeicon from "../assets/media/viewemployeeicon.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import Swal from "sweetalert2";
// import axios from "axios";
// import { baseUrl } from "../constants/Constants";
// import { getAllEmployees } from "../services/employes/Employee";
import Topheader from "../components/Topheader";
// import Foldericon from "../assets/media/Foldericon.svg";
import { useGetViewFoldersQuery } from "../apis/folderApi/folderApi";

const ViewFolder = () => {
  const [queryParameters] = useSearchParams();

  const folderId = Number(queryParameters.get("id"));

  const { data, isSuccess, isLoading, error } = useGetViewFoldersQuery({
    id: folderId,
  });
  // console.log("folderId: ", folderId);

  // const [data, setData] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   fetchData();
  // }, [isLoading]);
  // const fetchData = async () => {
  //   const response = await getAllEmployees();
  //   if (response.status === 200) {
  //     setData(response.data.data);
  //     setIsLoading(false);
  //   } else {
  //     setIsLoading(false);
  //     Swal.fire({
  //       icon: "error",
  //       title: response.data.message || "Server Error",
  //       timer: 2000,
  //       showConfirmButton: false,
  //     });
  //   }
  // };

  // const handleDelete = async (id) => {
  //   Swal.fire({
  //     title: "Delete Folder?",
  //     confirmButtonText: `Confirm`,
  //     showCancelButton: true,
  //     cancelButtonText: "Cancel",
  //     reverseButtons: true,
  //     customClass: {
  //       title: "title-class",
  //       htmlContainer: "text-class",
  //       confirmButton: "button-class",
  //       cancelButton: "deny-button-class",
  //     },
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       const data = {
  //         userId: id,
  //       };

  //       try {
  //         const config = {
  //           headers: {
  //             Authorization: `Bearer ${
  //               JSON.parse(localStorage.getItem("photolist")).data.token
  //             }`,
  //           },
  //         };
  //         const resp = await axios.post(
  //           `${baseUrl}/admin/employee/deleteEmployee`,
  //           data,
  //           config
  //         );
  //         setIsLoading(true);
  //         Swal.fire({
  //           icon: "success",
  //           title: resp.data.message || "Server Error",
  //           timer: 2000,
  //           showConfirmButton: false,
  //         });
  //       } catch (error) {
  //         Swal.fire({
  //           icon: "error",
  //           title: error.data.message || "Server Error",
  //           timer: 2000,
  //           showConfirmButton: false,
  //         });
  //       }
  //     }
  //   });
  // };

  const columns = [
    { name: "No#", selector: "id" },
    {
      name: "Employee Name",
      selector: "EmployeeName",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.ImageUrl ? (
            <img
              src={row.ImageUrl}
              alt={row.EmployeeName}
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
          ) : null}
          {row.EmployeeName}
        </div>
      ),
    },
    { name: "Email", selector: "Email", sortable: true },
    {
      name: "Price",
      selector: "price",
    },
    {
      name: "Folder Id",
      selector: "folderId",
    },
  ];

  let content;

  if (isLoading) {
    content = <h1>Loading...</h1>;
  }
  if (isSuccess) {
    content = <DataTable columns={columns} data={data.data.photoListings} />;
  }

  if (error) {
    content = <h1>There is no record in this folder</h1>;
  }

  return (
    // <>
    // <div className='main-table'>
    //         <div className="inner-table relative">
    //   {isLoading ? (
    //     <div className="spinner-container">
    //       <div className="loading-spinner"></div>
    //     </div>
    //   ) : (
    //     <DataTable columns={columns} data={data} pagination/>
    //   )}
    //   </div>
    //   </div>
    // </>

    <div className="main-table">
      <Topheader />
      <div className="inner-table relative">
        <div className="user-data px-[40px] py-[20px]">
          <div className="flex">
            <h2 className="text-MainHeading  text-DashHeading font-fwRegular">
              Folder Name
            </h2>
          </div>
          <div className="user-data-info">
            {/* {loder ? "Loading..." : <Carlisttable data={data} setLoder={setLoder} />} */}
            {/* <button type='submit' className='w-40 mt-3 bg-btn download-csv my text-paragraph rounded-[26px] py-[10px] text-[#ffffff]'>Add New Folder</button> */}
            {/* <DataTable columns={columns} data={data} pagination /> */}
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewFolder;
