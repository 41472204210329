import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import notificationicon from '../assets/media/notificationicon.svg';
import userimg from '../assets/media/userimg.svg';
function Topheader() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  
  const handleLogout = () => {
    localStorage.removeItem('photolist');
    navigate('/');
  };  

  return (
    <div className='bg-topHeadercolor px-[40px] py-[10px]'>
      <div className='flex justify-end'>
          {/* <div className='notifications mx-[5px]'>
            <img src={notificationicon} width={"40px"}  />
            </div> */}
        <div
          className={`user-img mx-[5px] ${isDropdownOpen ? 'user-img-dropdown open' : 'user-img-dropdown'}`}
          onClick={toggleDropdown}
        >
          <img src={userimg} width='40px' alt='User' />
          {isDropdownOpen && (
            <div className='dropdown-menu absolute bg-white border border-gray-300 rounded mt-2 z-50'>
              <button
                onClick={handleLogout}
                className='block w-full px-4 py-2 text-gray-800 hover:bg-gray-100'
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}



export default Topheader
