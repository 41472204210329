import React, { useEffect, useState } from "react";
import viewdemoemployee from "../assets/media/viewdemoemployee.svg";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { editEmploye, getEmployeById } from "../services/employes/Employee";
import Swal from "sweetalert2";
import addusericon from "../assets/media/addusericon.svg";
import { uploadFile } from "../services/UploadFile";

function Editemployee() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // get data
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState();
  const imagesPreview = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
    }
  };

  console.log(imgData);
  useEffect(() => {
    fetchData();
  }, []);
  const [queryParameters] = useSearchParams();

  const fetchData = async () => {
    try {
      const response = await getEmployeById(Number(queryParameters.get("id")));
      reset(response.data.data);
      setImgData(response.data.data.image);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.data.message || "Server Error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  // form
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (fData) => {
    setIsSubmitting(true);

    const data = {
      userId: Number(queryParameters.get("id")),
      name: fData.name,
      email: fData.email,
      password: fData.password,
      roleId: 2,
    };

    if (picture !== null) {
      const formDataImg = new FormData();
      formDataImg.append("file", picture);
      const imageResponse = await uploadFile(formDataImg);
      data.profilePic = imageResponse.data.data.fileUrl;
    }

    const response = await editEmploye(data);

    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: response.data.message,
        timer: 2000,
        showConfirmButton: false,
      });
      navigate("/home");
    } else {
      setIsSubmitting(false);

      Swal.fire({
        icon: "error",
        title: response.data.message,
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };
  return (
    <div className="user-data px-[40px] py-[20px]">
      <div className="flex">
        <h2 className="text-MainHeading  text-DashHeading font-fwRegular">
          Edit Employee
        </h2>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="user-data-info relative"
      >
        <label htmlFor="imageUpload" className="new-user-img">
          {imgData ? (
            <img
              className="userProfileImg"
              src={imgData}
              width={"100%"}
              style={{ objectFit: "cover", borderRadius: "50%" }}
              alt=""
            />
          ) : (
            <img className="editImg" src={addusericon} width={"50px"} alt="" />
          )}
          <input
            onChange={imagesPreview}
            type="file"
            id="imageUpload"
            accept="image/*"
          />
        </label>

        <div className="flex w-full">
          <div className="w-4/12">
            <div className="mt-12 mx-3">
              <label className="font-medium text-paragraph font-fwRegular ">
                Employee Name
              </label>
              <input
                {...register("name", { required: true })}
                type="text"
                className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px]  rounded-[24px] border-[#F1F1F1]"
                placeholder="Employee Name"
              />
              {errors.name && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
          </div>
          <div className="w-4/12">
            <div className="mt-12 mx-3">
              <label className="font-medium text-paragraph font-fwRegular ">
                Emial ID
              </label>
              <input
                {...register("email", { required: true })}
                type="email"
                className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px]  rounded-[24px] border-[#F1F1F1]"
                placeholder="Email Address"
              />
              {errors.email && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
          </div>
          <div className="w-4/12">
            <div className="mt-12 mx-3">
              <label className="font-medium text-paragraph font-fwRegular ">
                Password
              </label>
              <input
                {...register("password", { required: true })}
                type="password"
                className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px]  rounded-[24px] border-[#F1F1F1]"
                placeholder="Password"
              />
              {errors.password && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-40 bg-btn float-right mt-12 mx-2 text-paragraph rounded-[26px] py-[10px] text-[#ffffff]"
          disabled={isSubmitting}
        >
          Save
        </button>
      </form>
      <Link to="/home">
        <button className="w-40 mt-7 float-right mx-2 border-[1px] py-[10px] border-lightblue rounded-[20px] text-lightblue ">
          Cancel
        </button>
      </Link>
    </div>
  );
}

export default Editemployee;
