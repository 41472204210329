import React from 'react'
// import Footer from './Footer';
import Sidebar from './Sidebar';
//  './Sidebar';
function Layout({ children }) {
    return (
        <div className='flex'>
            <Sidebar/>
            {children}
            {/* <Footer /> */}
        </div>
    )
}

export default Layout