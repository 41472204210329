import React from "react";
import "./App.css";
import { Route, Routes, HashRouter } from "react-router-dom";
import Login from "./login";
import Home from "./home";
import Addemployee from "./components/Addemployee";
import Layout from "./components/Layout";
import Viewemployee from "./components/Viewemployee";
import Maineditemployee from "./components/Maineditemployee";
import Maincarlist from "./components/Maincarlist";
import Mainratingcarlist from "./components/Mainratingcarlist";
import Mainlevelpricing from "./components/Mainlevelpricing";
import EditPricing from "./components/EditPricing";
import PrivateRoute from "./hoc/PrivateRoute";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ViewCarListData from "./components/ViewCarListData";
import FoldersDatatable from "./Folders/FoldersDatatable";
import ViewFolder from "./Folders/ViewFolder";
import AddFolderModal from "./Folders/AddFolderModal";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Layout>
                <Home />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/home/viewemployee"
          element={
            <PrivateRoute>
              <Layout>
                <Viewemployee />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/home/addemployee"
          element={
            <PrivateRoute>
              <Layout>
                <Addemployee />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/home/editemployee"
          element={
            <PrivateRoute>
              <Layout>
                <Maineditemployee />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/carlist"
          element={
            <PrivateRoute>
              <Layout>
                <Maincarlist />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/folders"
          element={
            <PrivateRoute>
              <Layout>
                <FoldersDatatable />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/ViewFolder"
          element={
            <PrivateRoute>
              <Layout>
                <ViewFolder />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/AddFolderModal"
          element={
            <PrivateRoute>
              <AddFolderModal />
            </PrivateRoute>
          }
        />
        <Route
          path="/carlist/ratingcarlist"
          element={
            <PrivateRoute>
              <Layout>
                <Mainratingcarlist />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/carlist/viewratingcarlist"
          element={
            <PrivateRoute>
              <Layout>
                <ViewCarListData />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/levelpricing"
          element={
            <PrivateRoute>
              <Layout>
                <Mainlevelpricing />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/levelpricing/editpricing"
          element={
            <PrivateRoute>
              <Layout>
                <EditPricing />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
