import React, { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import deleteicon from "../assets/media/deleteicon.svg";
import levelediticon from "../assets/media/levelediticon.svg";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import moment from "moment";
import { deleteCar } from "../services/photolisting/car";
import viewemployeeicon from "../assets/media/viewemployeeicon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddToFolderModal from "../Folders/AddToFolderModal";

const Carlisttable = ({ data, setLoder }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [dateSearch, setDateSearch] = useState(null); // State for date search
  const [selectedFolders, setSelectedFolders] = useState([]); // State for selected folder IDs

  const csvHeaders = [
    { label: "No#", key: "id" },
    { label: "Images", key: "ImageUrl" },
    { label: "Name", key: "EmployeeName" },
    { label: "Email", key: "Email" },
    { label: "Price", key: "price" },
    { label: "User Id", key: "userId" },
    { label: "Level", key: "levelId" },
    { label: "Created on", key: "createdOn" },
  ];

  const filteredData = useMemo(() => {
    let filtered = data;

    if (searchQuery) {
      filtered = filtered.filter((row) =>
        row.EmployeeName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (dateSearch) {
      filtered = filtered.filter((row) =>
        row.createdOn.includes(moment(dateSearch).format("YYYY-MM-DD"))
      );
    }

    return filtered;
  }, [data, searchQuery, dateSearch]);

  const [AddToFolderModals, setAddToFolderModal] = useState(false);
  const AddToFolderHandler = () => {
    setAddToFolderModal(true);
  };

  const handleRowSelected = (state) => {
    // Extract the selected rows' IDs
    const selectedRows = state.selectedRows.map((row) => row.id);
    setSelectedFolders(selectedRows);
  };

  const columns = [
    { name: "No#", selector: "id", sortable: true },
    {
      name: "Images",
      cell: (row) => (
        <img
          src={row.ImageUrl}
          alt={row.name}
          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
        />
      ),
    },
    { name: "Name", selector: "EmployeeName", sortable: true },
    { name: "Email", selector: "Email", sortable: true },
    {
      name: "Level",
      cell: (row) =>
        row["levelId"] === 4 ? (
          <div className="bg-[#C4E1FF] rounded-xl text-black w-20 h-7 text-center flex justify-center items-center border-[#D9D9D9] border ">
            4
          </div>
        ) : row["levelId"] === 3 ? (
          <div className="bg-gradient-to-r from-[#8639FF] to-[#BE51FF] rounded-xl text-white  w-20 h-7 text-center flex justify-center items-center border-[#D9D9D9] border">
            3
          </div>
        ) : row["levelId"] === 2 ? (
          <div className="bg-gradient-to-r from-[#8639FF] to-[#BE51FF] rounded-xl text-white  w-20 h-7 text-center flex justify-center items-center border-[#D9D9D9] border">
            2
          </div>
        ) : (
          <div className="bg-gradient-to-r from-[#8639FF] to-[#BE51FF] rounded-xl text-white  w-20 h-7 text-center flex justify-center items-center border-[#D9D9D9] border">
            1
          </div>
        ),
    },
    {
      name: "Created on",
      selector: "Created on",
      sortable: true,
      cell: (row) => {
        return (
          <span>
            {row?.createdOn
              ? moment(row["createdOn"]).format("DD-MM-YYYY")
              : "Unavailable"}
          </span>
        );
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Link to={`/carlist/viewratingcarlist?id=${row.id}`}>
            <button className="mx-1">
              <img src={viewemployeeicon} width={"17px"} />
            </button>
          </Link>
          <Link to={`/carlist/ratingcarlist?id=${row.id}`}>
            <button className="mx-1" onClick={() => console.log("Edit")}>
              <img src={levelediticon} width={"17px"} />
            </button>
          </Link>
          <button
            className="mx-1"
            onClick={() =>
              Swal.fire({
                title: "Remove Employee?",
                cancelButtonText: "Cancel",
                reverseButtons: false,
                confirmButtonText: `Confirm`,
                showCancelButton: true,
                customClass: {
                  title: "title-class",
                  htmlContainer: "text-class",
                  confirmButton: "button-class",
                  cancelButton: "deny-button-class",
                },
              }).then(async (result) => {
                if (result.isConfirmed) {
                  const data = {
                    photoListingId: Number(row.id),
                  };
                  const resp = await deleteCar(data);
                  if (resp.status === 200) {
                    setLoder(true);
                    Swal.fire({
                      title: resp.data.message,
                      timer: 2000,
                      icon: "success",
                      showConfirmButton: false,
                    });
                  } else {
                    // setLoder(true)
                    Swal.fire({
                      title: resp.data.message,
                      timer: 2000,
                      icon: "error",
                      showConfirmButton: false,
                    });
                  }
                }
              })
            }
          >
            <img src={deleteicon} width={"17px"} />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="search-container">
        <div className="SearchMenu">
          <div>
            <label>Employee Name:</label>
            <br></br>
            <input
              type="text"
              placeholder="Search by name..."
              value={searchQuery}
              className="SearchName search-bar"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="mx-3">
            <label>Date:</label>
            <br></br>
            <DatePicker
              selected={dateSearch}
              className="SearchDate search-bar"
              onChange={(date) => setDateSearch(date)}
              placeholderText="Search by date..."
            />
          </div>
        </div>
      </div>
      <div className="download-csv">
        <button
          className="AddToFolderbtn w-40 mr-2 text-btn text-paragraph rounded-[26px] py-[10px] border border-btn"
          onClick={() => AddToFolderHandler()}
        >
          Add to Folder
        </button>
        <CSVLink
          data={filteredData}
          headers={csvHeaders}
          filename={"employee_data.csv"}
          className="btn btn-primary"
        >
          <button
            type="submit"
            className="w-40 mt-3 bg-btn  my text-paragraph rounded-[26px] py-[10px] text-[#ffffff]"
          >
            Download CSV
          </button>
        </CSVLink>
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        selectableRows
        onSelectedRowsChange={handleRowSelected}
      />
      {AddToFolderModals && (
        <AddToFolderModal
          setAddToFolderModal={setAddToFolderModal}
          AddToFolderModals={AddToFolderModals}
          selectedFolders={selectedFolders}
        />
      )}
    </>
  );
};

export default Carlisttable;
