import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const folderAPI = createApi({
  reducerPath: "folderAPI",
  baseQuery: fetchBaseQuery({ baseUrl: `https://api.photolistpro.com/api` }),
  tagTypes: ["Folders"],
  endpoints: (builder) => ({
    getFolders: builder.query({
      query: () => ({
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("photolist"))?.data?.token
          }`,
        },
        url: "/admin/folder/getAllFolders",
        method: "GET",
        provideTags: ["Folders"],
      }),
    }),
    deleteFolders: builder.mutation({
      query: ({ id }) => ({
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("photolist"))?.data?.token
          }`,
        },
        url: `/admin/folder/deleteFolder/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Folders"],
    }),
    createFolders: builder.mutation({
      query: (payload) => ({
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("photolist"))?.data?.token
          }`,
        },
        url: `/admin/folder/createFolder`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Folders"],
    }),
    addToFolder: builder.mutation({
      query: (payload) => ({
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("photolist"))?.data?.token
          }`,
        },
        url: `/admin/folder/addListingToFolder`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Folders"],
    }),
    updateFolders: builder.mutation({
      query: (payload) => ({
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("photolist"))?.data?.token
          }`,
        },
        url: `/admin/folder/updateFolderName`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Folders"],
    }),
    getViewFolders: builder.query({
      query: ({ id }) => ({
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("photolist"))?.data?.token
          }`,
        },
        url: `/admin/folder/getListingByFolder/${id}`,
        method: "GET",
        provideTags: ["Folders"],
      }),
    }),
  }),
});

export const {
  useGetFoldersQuery,
  useDeleteFoldersMutation,
  useCreateFoldersMutation,
  useUpdateFoldersMutation,
  useAddToFolderMutation,
  useGetViewFoldersQuery,
} = folderAPI;
