// import { config } from "../constants/Constants";
import axios from "../hoc/axios";

export const uploadFile = async (body) => {
    try {
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('photolist'))?.data?.token}` }
        };
        const response = await axios.post("/admin/file/upload", body, config);
        return response;
    } catch (err) {
        return err.response
    }
}
