import React from "react";
// import Student_table from './Student_table'
import Topheader from "./Topheader";
// import Usermanagedata from "./Usermanagedata";
import EditPricingDetails from "./EditPricingDetails";
function  EditPricing() {
  return (
    <>
      <div className="main-table">
        <Topheader />
        <div className="inner-table relative">
        <EditPricingDetails/>
        </div>
      </div>
    </>
  );
}

export default  EditPricing
