import React from "react";
import Topheader from "./Topheader";
import Carlistdetails from "./Carlistdetails";
function Maincarlist() {

  return (
    <>
      <div className="main-table">
        <Topheader />
        <div className="inner-table relative">
          <Carlistdetails/>
        </div>
      </div>
    </>
  );
}

export default Maincarlist
