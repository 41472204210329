import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { baseUrl } from "../constants/Constants";
import hidePass from "../assets/media/hidePass.svg";

function Loginform() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); 

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${baseUrl}/admin/dashboard/login`, data);
      console.log(response.data);

      if (response.status === 200) {
        localStorage.setItem("photolist", JSON.stringify(response.data));
        navigate("/home");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
  
    const token = localStorage.getItem("photolist");
    if (token) {
      navigate("/home");
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="mt-7">
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className="font-medium text-paragraph font-fwRegular">
          Email
        </label>
        <input
          type="text"
          className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px]  rounded-[24px] border-[#F1F1F1]"
          placeholder="Enter Email Address"
          {...register("email", { required: true })}
        />
        {errors.email && (
          <span style={{ color: "red" }}>This field is required</span>
        )}
        <br></br>
        <label className="font-medium text-paragraph font-fwRegular">
          Password
        </label>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            className="w-full text-paragraph px-[24px] mt-3 py-[15px] border-[1px]  rounded-[24px] border-[#F1F1F1]"
            placeholder="Enter Password"
            {...register("password", { required: true })}
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute right-3 top-1/2 transform -translate-y-1/2"
          >
            {showPassword ? <img className="mt-3 mr-2" src={hidePass} width="25px" alt="" /> : <img  
            className="mt-3 mr-2" src={hidePass} width="25px" alt="" />}
          </button>
        </div>
        {errors.password && (
          <span style={{ color: "red" }}>This field is required</span>
        )}
        <button
          type="submit"
          className="bg-btn my text-paragraph rounded-[26px] py-[16px] text-[#ffffff] w-full mt-[10px]"
        >
          Continue
        </button>
      </form>
    </div>
  );
}

export default Loginform;
