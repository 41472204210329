import React, { useState } from "react";
import levelediticon from "../assets/media/levelediticon.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { updateLevels } from "../services/levels/level";
import Swal from "sweetalert2";
function EditPricingDetails() {
  const navigate = useNavigate();
  const [isactive, setIsActive] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (fData) => {
    setIsActive(true)
    const data = {
      levelId: Number(fData.level),
      price: Number(fData.price)
    }
    // console.log(data);
    const resp = await updateLevels(data)
    if (resp.status === 200) {
      Swal.fire({
        icon: "success",
        title: resp.data.message,
        timer: 2000,
        showConfirmButton: false,
      });
      navigate("/levelpricing")
      setIsActive(false)
    } else {
      Swal.fire({
        icon: "error",
        title: resp.data.message,
        timer: 2000,
        showConfirmButton: false,
      });
      setIsActive(false)
    }
  }
  return (
    <div className="user-data px-[40px] py-[20px]">
      <div className="flex">
        <h2 className="text-MainHeading  text-DashHeading font-fwRegular">
          Edit Pricing
        </h2>

      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="user-data-info pricing">
        <div className="flex w-full">
          <div className="w-4/12 edit-pricing-box">
            <div className="mt-3 mx-4">
              <label className="font-medium text-paragraph flex font-fwRegular ">
                Select Level
                <img className="ml-auto edit-pricing-icon" src={levelediticon} alt="" />
              </label>

              <select className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px]  rounded-[24px]  border-[#F1F1F1]"  {...register("level", { required: true })}>
                <option selected disabled="disabled" value="">Select Option</option>
                <option value={1}>Level One</option>
                <option value={2}>Level Two</option>
                <option value={3}>Level Three</option>
                <option value={4}>Level Four</option>
              </select>
            </div>
            {errors.level && (
              <span style={{ color: "red" }}>This field is required</span>
            )}
          </div>
          <div className="w-4/12 edit-pricing-box">
            <div className="mt-3 mx-4">
              <label className="font-medium text-paragraph flex font-fwRegular ">
                Level Pricing
                {/* <img className="ml-auto edit-pricing-icon" src={levelediticon} alt="" /> */}
              </label>

              <input
                type="text"
                className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px]  rounded-[24px] border-[#F1F1F1]"
                placeholder="$1500"
                {...register("price", { required: true })}
              />
              {errors.price && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
          </div>


        </div>

        <button
          type="submit"
          className="w-40 bg-btn float-right mt-14 my text-paragraph rounded-[26px] py-[10px] text-[#ffffff]"
          disabled={isactive}
        >
          Update
        </button>
      </form>


    </div>
  );
}

export default EditPricingDetails;
