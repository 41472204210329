import { configureStore } from "@reduxjs/toolkit";
import { folderAPI } from "./apis/folderApi/folderApi";

export const store = configureStore({
  reducer: {
    [folderAPI.reducerPath]: folderAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(folderAPI.middleware),
});
