import React, { useState } from "react";
import addusericon from "../assets/media/addusericon.svg";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { addEmploye } from "../services/employes/Employee";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "../services/UploadFile";

function Newemployee() {
  const navigate = useNavigate();
  // for image
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState();
  const imagesPreview = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (fData) => {
    if (picture) {
      setIsSubmitting(true);
      const data = {
        name: fData.name,
        email: fData.email,
        password: fData.password,
        roleId: 2,
        // profilePic: "Sample URL"
      };
      const formDataImg = new FormData();
      formDataImg.append("file", picture);
      const imageResponse = await uploadFile(formDataImg);
      data.profilePic = imageResponse.data.data.fileUrl;

      const response = await addEmploye(data);

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: response.data.message,
          timer: 2000,
          showConfirmButton: false,
        });
        navigate("/home");
      } else {
        setIsSubmitting(false);

        Swal.fire({
          icon: "error",
          title: response.data.message,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Please upload picture",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div className="user-data px-[40px] py-[20px]">
      <div className="flex">
        <h2 className="text-MainHeading text-DashHeading font-fwRegular">
          Add Employee
        </h2>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        className="user-data-info relative"
      >
        <input autocomplete="false" name="hidden" type="text" class="hidden" />
        <label htmlFor="imageUpload" className="new-user-img">
          {imgData ? (
            <img
              src={imgData}
              className="uploadNewImage"
              style={{ objectFit: "cover", borderRadius: "50%" }}
              alt=""
            />
          ) : (
            <img src={addusericon} width={"50px"} alt="" />
          )}
          <input
            onChange={imagesPreview}
            type="file"
            autocomplete="new-password"
            id="imageUpload"
            accept="image/*"
          />
        </label>
        <div className="flex w-full">
          <div className="w-4/12">
            <div className="mt-12 mx-3">
              <label className="font-medium text-paragraph font-fwRegular">
                Employee Name
              </label>
              <input
                type="text"
                className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px] rounded-[24px] border-[#F1F1F1]"
                placeholder="Employee Name"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
          </div>
          <div className="w-4/12">
            <div className="mt-12 mx-3">
              <label className="font-medium text-paragraph font-fwRegular">
                Email ID
              </label>
              <input
                type="email"
                className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px] rounded-[24px] border-[#F1F1F1]"
                placeholder="Email Address"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span style={{ color: "red" }}>Please enter an email</span>
              )}
            </div>
          </div>
          <div className="w-4/12">
            <div className="mt-12 mx-3">
              <label className="font-medium text-paragraph font-fwRegular">
                Password
              </label>
              <input
                type="password"
                className="w-full text-paragraph px-[24px] my-3 py-[15px] border-[1px] rounded-[24px] border-[#F1F1F1]"
                placeholder="Password"
                {...register("password", { required: true })}
              />
              {errors.password && (
                <span style={{ color: "red" }}>Please enter a password</span>
              )}
            </div>
          </div>
        </div>
        <button
          disabled={isSubmitting}
          type="submit"
          className="w-40 bg-btn float-right mt-12 my text-paragraph rounded-[26px] py-[10px] text-[#ffffff]"
        >
          Add Employee
        </button>
      </form>
    </div>
  );
}

export default Newemployee;
