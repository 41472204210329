import React, { useEffect, useState } from 'react';
import { getSingleCar } from '../services/photolisting/car';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function ViewCarListData() {
  const [imgData, setImgData] = useState();
  const [queryParameters] = useSearchParams();
  const [lightboxOpen, setLightboxOpen] = useState(false); // State to control Lightbox visibility
  const [carData, setCarData] = useState(null); // State to store car data

  const openLightbox = () => {
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  // getData
  const getFunc = async () => {
    const response = await getSingleCar(queryParameters.get("id"));
    if (response.status === 200) {
      setCarData(response.data.data); // Store car data from API response
      setImgData(response?.data?.data?.ImageUrl);
    } else {
      Swal.fire({
        icon: "error",
        title: response.data.message || "Server Error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };
  
  useEffect(() => {
    getFunc();
  }, []);

  return (
    <div className="user-data px-[40px] py-[20px] w-[80%]">
      <div className="flex">
        <h2 className="text-MainHeading text-DashHeading font-fwRegular">
          View Car List
        </h2>
      </div>
      <div className='user-data-info view-employee-details'>
        <div className='flex'>
          <div className="w-full Lightbox-top-img">
            <img
              src={imgData}
              className="cursor-pointer"
              style={{
                borderRadius: "10px",
              }}
              onClick={openLightbox}
            />
            {lightboxOpen && (
              <div className="custom-lightbox-overlay">
                <div className="custom-lightbox">
                  <img src={imgData} alt="Image" className="lightbox-image" />
                  <button className="close-button" onClick={closeLightbox}>
                    X
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='user-data-info view-employee-details'>
          <div className='List-Data'>
            <div className="grid grid-cols-4 gap-4 List-Data-deatils">
              <div className='mx-2 w-full'>
                <label className='font-medium text-paragraph font-fwRegular '>Main ID</label>
                <br />
                <p className='w-full text-paragraph px-[1px] my-3 rounded-[24px] border-[#F1F1F1]'>
                  {carData?.id}
                </p>
              </div>
              <div className='mx-2 w-full'>
                <label className='font-medium text-paragraph font-fwRegular '>User ID</label>
                <br />
                <p className='w-full text-paragraph px-[1px] my-3 rounded-[24px] border-[#F1F1F1]'>
                  {carData?.userId}
                </p>
              </div>
              <div className='mx-2 w-full'>
                <label className='font-medium text-paragraph font-fwRegular '>Employee Name</label>
                <br />
                <p className='w-full text-paragraph px-[1px] my-3 rounded-[24px] border-[#F1F1F1]'>
                  {carData?.EmployeeName}
                </p>
              </div>
              <div className='mx-2 w-full'>
                <label className='font-medium text-paragraph font-fwRegular '>Email</label>
                <br />
                <p className='w-full text-paragraph px-[1px] my-3 rounded-[24px] border-[#F1F1F1]'>
                  {carData?.Email}
                </p>
              </div>
            
            </div>
            <div className="grid grid-cols-4 gap-4 List-Data-deatils mt-5">
              <div className='mx-2 w-full'>
                <label className='font-medium text-paragraph font-fwRegular '>Pricing</label>
                <br />
                <p className='w-full text-paragraph px-[1px] my-3 rounded-[24px] border-[#F1F1F1]'>
                  {carData?.price}
                </p>
              </div>
              <div className='mx-2 w-full'>
                <label className='font-medium text-paragraph font-fwRegular '>Created On</label>
                <br />
                <p className='w-full text-paragraph px-[1px] my-3 rounded-[24px] border-[#F1F1F1]'>
                {carData?.createdOn ? new Date(carData.createdOn).toISOString().split('T')[0] : ''}
                </p>
              </div>
              <div className='mx-2 w-full'>
                <label className='font-medium text-paragraph font-fwRegular '>Level </label>
                <br />
                <p className='w-full text-paragraph px-[1px] my-3 rounded-[24px] border-[#F1F1F1]'>
                  {carData?.levelId}
                </p>
              </div>           
            </div>
          </div>
      </div>
    </div>
  );
}

export default ViewCarListData;
