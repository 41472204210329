import React, { useEffect, useState } from "react";
import Topheader from "./Topheader";
import Levelpricingdetails from "./Levelpricingdetails";
import { getAllLevels } from "../services/levels/level";
import Swal from "sweetalert2";
function Mainlevelpricing() {

  const [data, setData] = useState()
  // console.log(data);
  const getFunc = async () => {
    const response = await getAllLevels();
    if (response.status === 200) {
      setData(response.data.data);
    } else {
      Swal.fire({
        icon: "error",
        title: response.data.message || "Server Error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };
  useEffect(() => {
    getFunc();
  }, []);
  return (
    <>
      <div className="main-table">
        <Topheader />
        <div className="inner-table relative">
          <Levelpricingdetails data={data} />
        </div>
      </div>
    </>
  );
}

export default Mainlevelpricing
