import React, { useEffect, useState } from "react";
// import viewdemoemployee from "../assets/media/viewdemoemployee.svg";
import editicon from "../assets/media/editicon.svg";
import { Link, useSearchParams, useNavigate  } from 'react-router-dom';
import { getEmployeById } from "../services/employes/Employee";
import Swal from "sweetalert2";
import axios from 'axios';
import { baseUrl } from '../constants/Constants';
function Viewemployeedetails() {
  const [data, setData] = useState([]);
  const [img, setImg] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    fetchData();
  }, []);
  const [queryParameters] = useSearchParams();

  const fetchData = async () => {
    try {
      const response = await getEmployeById(Number(queryParameters.get("id")));
      setData(response.data.data);
      setImg(response.data.data.image);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.data.message || "Server Error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };



 
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Remove Employee?",
      confirmButtonText: `Confirm`,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      customClass: {
        title: 'title-class',
        htmlContainer: 'text-class',
        confirmButton: 'button-class',
        cancelButton: 'deny-button-class',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const payload = {
          userId: id
        };

        try {
          const config = {
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('photolist')).data.token}` }
          };
          const response = await axios.post(`${baseUrl}/admin/employee/deleteEmployee`, payload, config);
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: response.data.message || "Server Error",
            timer: 2000,
            showConfirmButton: false,
          });
          // Navigate back to some other page, assuming '/home' for now
          navigate('/home');
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: error.data.message || "Server Error",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  



  return (
    <div className="user-data px-[40px] py-[20px]">
      <div className="flex">
        <h2 className="text-MainHeading  text-DashHeading font-fwRegular">
          View Employee
        </h2>

      </div>
      <div className="user-data-info relative view-employee-details">
        <div className="new-user-img-demo">
          <img src={img} className="view-img" alt="" />
        </div>
        <Link to={`/home/editemployee?id=${queryParameters.get("id")}`}>
          <div className="edite-profile flex float-right border-[1px] border-lightblue rounded-[20px]">
            <img src={editicon} className="mr-2 ml-3" alt="" width={"15px"} />
            <button className="edit-profile text-lightblue ml-2 mr-3 py-2 "> Edit profile</button>
          </div>
        </Link>
        <div className="flex w-full">
          <div className="w-3/12">
            <div className="mt-12 mx-3 border-r-[1px] border-color-[#D9D9D9]">
              <label className="font-medium text-paragraph font-fwRegular ">
                Employee Name
              </label>
              <p className="mt-3">{data?.name}</p>
            </div>
          </div>
          <div className="w-3/12">
            <div className="mt-12 mx-3 border-r-[1px] border-color-[#D9D9D9]">
              <label className="font-medium text-paragraph font-fwRegular ">
                Email Id
              </label>
              <p style={{wordBreak:"break-all"}} className="mt-3">{data?.email}</p>
            </div>
          </div>
          <div className="w-3/12">
            <div className="mt-12 mx-3 border-r-[1px] border-color-[#D9D9D9]">
              <label className="font-medium text-paragraph font-fwRegular ">
                Joining Date

              </label>
              <p className="mt-3">12/06/2023</p>
            </div>
          </div>
          <div className="w-3/12">
            <div className="mt-12 mx-3 ">
              <label className="font-medium text-paragraph font-fwRegular ">
                Password

              </label>
              <p className="mt-3">***********</p>
            </div>
          </div>
        </div>
      </div>

      <button
        type="submit"
        className="w-40 bg-btn float-right mt-8 my text-paragraph rounded-[26px] py-[10px] text-[#ffffff]"
        onClick={() => handleDelete(queryParameters.get("id"))}
      >
        Remove Employee
      </button>

    </div>
  );
}

export default Viewemployeedetails;
