import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom';
import Carlisttable from './Carlisttable'
import { getAllCars } from '../services/photolisting/car';
import Swal from 'sweetalert2';
function Carlistdetails() {
  const [loder, setLoder] = useState(true)
  const [data, setData] = useState()

  const getFunc = async () => {
    const response = await getAllCars();
    if (response.status === 200) {
      setLoder(false)
      setData(response.data.data);
    } else {
      Swal.fire({
        icon: "error",
        title: response.data.message || "Server Error",
        timer: 2000,
        showConfirmButton: false,
      });
      setLoder(false)
    }
  };
  useEffect(() => {
    getFunc();
  }, [loder]);

  return (
    <div className='user-data px-[40px] py-[20px]'>
      <div className='flex'>
        <h2 className='text-MainHeading  text-DashHeading font-fwRegular'>Car Listing</h2>

      </div>
      <div className='user-data-info'>
        {loder ? "Loading..." : <Carlisttable data={data} setLoder={setLoder} />}
      </div>
    </div>
  )
}

export default Carlistdetails
