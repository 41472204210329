import React, { useState } from 'react';
import Foldericon from '../assets/media/Foldericon.svg'

function Folder() {
  const [isActive, setIsActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseDown = () => {
    setIsActive(true);
  };

  const handleMouseUp = () => {
    setIsActive(false);
    // Perform your action here, e.g., navigate to a different URL or update state.
    // Replace the console.log with your desired action.
    console.log('Clicked! Add your action here.');
  };

  const handleOnMouseEnter = () => {
    setIsHovered(true);
  };

  const handleOnMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`car-listing ${
        isActive || isHovered ? 'list-active bg-listActive' : ''
      }`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <div className='flex py-[30px] px-[40px]'>
        <div className='list-icon'>
          <img src={Foldericon} width={"23px"} alt="Logo" />
        </div>
        <div className='list-name pl-[10px]'>
          <p className='font-fwlight text-lightblue'>Folders</p>
        </div>
      </div>
    </div>
  );
}

export default Folder;
