import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import levelediticon from "../assets/media/levelediticon.svg";
import deleteicon from "../assets/media/deleteicon.svg";
import viewemployeeicon from "../assets/media/viewemployeeicon.svg";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import {
  useGetFoldersQuery,
  useDeleteFoldersMutation,
  useUpdateFoldersMutation,
} from "../apis/folderApi/folderApi";
import Topheader from "../components/Topheader";
import AddFolderModal from "./AddFolderModal";
// import EditFolderModal from "./EditFolderModal";

const Usertable = () => {
  const [AddFolderModals, setAddFolderModal] = useState(false);
  // const [editFolderName, setEditFolderName] = useState("");
  // const [EditFolderModals, setEditFolderModal] = useState(false);
  // const [singleFolderData, setSingleFolderData] = useState();
  const [recallApi, setRecallApi] = useState(false);
  const { data, isSuccess, isLoading, error, refetch } = useGetFoldersQuery([]);
  // console.log(data);
  const [deleteFolders] = useDeleteFoldersMutation();
  const [updateFolders] = useUpdateFoldersMutation();

  const AddFolderHandler = () => {
    setAddFolderModal(true);
  };

  // const EditFolderHandler = (data) => {
  //   setSingleFolderData(data);

  //   setEditFolderModal(true);
  // };

  useEffect(() => {
    if (recallApi) {
      refetch();
      setRecallApi(false);
    }
  }, [recallApi]);

  const columns = [
    { name: "No#", selector: "id", width: "15%" },
    { name: "Folder Name", selector: "name", sortable: true, width: "30%" },
    { width: "40%" },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {/* View Action */}
          <Link to={`/ViewFolder?id=${row.id}`}>
            <button className="mx-1">
              <img src={viewemployeeicon} alt="" width={"17px"} />
            </button>
          </Link>
          {/* Edit Action */}
          <button
            className="mx-1"
            onClick={() => {
              let editedFolderName = row.name;
              console.log("before edited value: ", editedFolderName);
              Swal.fire({
                title: "Edit Folder Name",
                cancelButtonText: "Cancel",
                reverseButtons: false,
                confirmButtonText: `Confirm`,
                showCancelButton: true,
                customClass: {
                  title: "title-class",
                  htmlContainer: "text-class",
                  confirmButton: "button-class",
                  cancelButton: "deny-button-class",
                  popup: "set-index",
                },
                input: "text",
                inputValue: row.name,
                preConfirm: (value) => {
                  editedFolderName = value;
                  console.log("After edited value : ", editedFolderName);
                },
              }).then(async (result) => {
                if (result.isConfirmed) {
                  const payload = {
                    folderId: row.id,
                    name: editedFolderName,
                  };
                  console.log("payload: ", payload);
                  const resp = await updateFolders(payload);
                  if (resp.data.data === true) {
                    setRecallApi(true);
                    Swal.fire({
                      title: resp.data.message,
                      timer: 2000,
                      icon: "success",
                      showConfirmButton: false,
                    });
                    setAddFolderModal(false);
                  } else {
                    Swal.fire({
                      title: resp.data.message,
                      timer: 2000,
                      icon: "error",
                      showConfirmButton: false,
                    });
                    setAddFolderModal(false);
                  }
                }
              });
            }}
          >
            <img src={levelediticon} alt="" width={"17px"} />
          </button>
          {/* Delete Action */}
          <button
            className="mx-1"
            onClick={() =>
              Swal.fire({
                title: "Remove Folder?",
                cancelButtonText: "Cancel",
                reverseButtons: false,
                confirmButtonText: `Confirm`,
                showCancelButton: true,
                customClass: {
                  title: "title-class",
                  htmlContainer: "text-class",
                  confirmButton: "button-class",
                  cancelButton: "deny-button-class",
                },
              }).then(async (result) => {
                if (result.isConfirmed) {
                  const resp = await deleteFolders({ id: row.id });
                  if (resp.data.data === true) {
                    setRecallApi(true);
                    Swal.fire({
                      title: resp.data.message,
                      timer: 2000,
                      icon: "success",
                      showConfirmButton: false,
                    });
                    setAddFolderModal(false);
                  } else {
                    Swal.fire({
                      title: resp.data.message,
                      timer: 2000,
                      icon: "error",
                      showConfirmButton: false,
                    });
                    setAddFolderModal(false);
                  }
                }
              })
            }
          >
            <img src={deleteicon} width={"17px"} />
          </button>
        </div>
      ),
      width: "15%",
    },
  ];

  let content;

  if (isLoading) {
    content = <h1>Loading...</h1>;
  }
  if (isSuccess) {
    content = <DataTable columns={columns} data={data.data} />;
  }

  if (error) {
    content = <h1>Something Went Wrong</h1>;
  }

  const onFolderCreated = () => {
    setRecallApi(true);
  };
  return (
    <div className="main-table">
      <Topheader />
      <div className="inner-table relative">
        <div className="user-data px-[40px] py-[20px]">
          <div className="flex">
            <h2 className="text-MainHeading  text-DashHeading font-fwRegular">
              Folders
            </h2>
          </div>
          <div className="user-data-info">
            <button
              className="w-40 mt-3 bg-btn download-csv my text-paragraph rounded-[26px] py-[10px] text-[#ffffff]"
              onClick={() => AddFolderHandler()}
            >
              Add New Folder
            </button>
            {content}
          </div>
        </div>
      </div>
      {AddFolderModals && (
        <AddFolderModal
          setAddFolderModal={setAddFolderModal}
          AddFolderModals={AddFolderModals}
          folderCreatedListener={onFolderCreated}
        />
      )}
      {/* {EditFolderModals && (
        <EditFolderModal
          setAddFolderModal={setEditFolderModal}
          AddFolderModals={EditFolderModals}
          folderCreatedListener={onFolderCreated}
          folderData={singleFolderData}
        />
      )} */}
    </div>
  );
};

export default Usertable;
