import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import levelediticon from "../assets/media/levelediticon.svg";
import deleteicon from '../assets/media/deleteicon.svg';
import viewemployeeicon from '../assets/media/viewemployeeicon.svg';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseUrl } from '../constants/Constants';
import { getAllEmployees } from '../services/employes/Employee';

const Usertable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, [isLoading]);
  // console.log(JSON.parse(localStorage.getItem('photolist'))?.data.token);
  const fetchData = async () => {
    const response = await getAllEmployees();
    if (response.status === 200) {
      setData(response.data.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: response.data.message || "Server Error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  }


  const handleDelete = async (id) => {
    Swal.fire({
      title: "Remove Employee?",
      confirmButtonText: `Confirm`,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      customClass: {
        title: 'title-class',
        htmlContainer: 'text-class',
        confirmButton: 'button-class',
        cancelButton: 'deny-button-class',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          userId: id
        };

        try {
          const config = {
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('photolist')).data.token}` }
          };
          const resp = await axios.post(`${baseUrl}/admin/employee/deleteEmployee`, data, config);
          setIsLoading(true);
          Swal.fire({
            icon: "success",
            title: resp.data.message || "Server Error",
            timer: 2000,
            showConfirmButton: false,
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: error.data.message || "Server Error",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  const columns = [
    { name: 'No#', selector: 'id' },
    {
      name: 'Employee',
      selector: 'name',
      cell: (row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {row.image ? ( 
        <img
            src={row.image}
            alt={row.name}
            style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
          />
          ) : null}
          {row.name}
        </div>
        
      ),
    },
    { name: 'Email', selector: 'email', sortable: true },
    {
      name: 'Password',
      selector: 'password',
      sortable: true,
      cell: (row) => {
        return <span>*******</span>
        ;
      },
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <Link to={`/home/viewemployee?id=${row.id}`}>
            <button className='mx-1' ><img src={viewemployeeicon} width={'17px'} /></button>
          </Link>
          <Link to={`/home/editemployee?id=${row.id}`}>
            <button className='mx-1'><img src={levelediticon} width={'17px'} /></button>
          </Link>
          <button className='mx-1' onClick={() => handleDelete(row.id)}><img src={deleteicon} width={'17px'} /></button>
        </div>
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <DataTable columns={columns} data={data} pagination/>
      )}
    </>
  );
};

export default Usertable;
